<template>
  <v-card flat class="py-3">
    <v-card-title class="pb-0 pt-0">Seleziona una lista</v-card-title>
    <v-card-text class="pb-0">
      <v-list class="overflow-y-auto" style="max-height: 300px">
        <template v-for="(list, index) in whishlistLists">
          <v-list-item :key="list.id" @click="selectList(list.id)">
            <v-list-item-title>
              <v-badge
                color="primary"
                inline
                :content="list.size"
                :value="list.size > 0"
              >
                {{ list.name }}
              </v-badge>
            </v-list-item-title>
            <!-- <v-list-item-avatar>
              <v-icon v-if="list.isDefault == true">mdi-star</v-icon>
            </v-list-item-avatar>
            <v-divider></v-divider> -->
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-form class="d-flex" @submit.prevent="createAndSelect">
        <v-text-field
          label="Crea una nuova lista"
          hide-details="auto"
          v-model="listName"
          dense
          required
        ></v-text-field>
        <v-btn type="submit" depressed small color="primary">CREA</v-btn>
      </v-form>
    </v-card-actions>
  </v-card>
</template>
<script>
import list from "~/mixins/list";

export default {
  name: "ListSelector",
  mixins: [list],
  computed: {
    whishlistLists() {
      return this.lists;
    }
  },
  methods: {
    selectList(listId) {
      this.$emit("submit", { listId: listId });
    },
    async createAndSelect() {
      let list = await this.createList();
      this.$emit("submit", { listId: list.id });
    }
  },
  created() {
    this.reload();
  }
};
</script>
