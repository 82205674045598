import ListSelector from "@/components/lists/ListSelector";
import ConfirmDelete from "@/components/navigation/ConfirmDelete.vue";

import login from "./login";

import { mapState, mapActions, mapGetters } from "vuex";

import CartService from "~/service/cartService";
import CatalogService from "~/service/catalogService";
import ListService from "~/service/listService";
import cartService from "~/service/cartService";
//import CustomMarketSuiteService from "../../service/marketsuiteCustomService";

export default {
  mixins: [login],
  data() {
    return {
      internalQuantity: global.config.updateCart == true ? 0 : -1,
      heartKey: 0,
      showQntyInput: false,
      qntyInput: 0,
      higherInput: 0,
      lowerInput: 0
    };
  },
  computed: {
    ...mapGetters({
      cartItemsCount: "cart/getItemsCount",
      isAuthenticated: "cart/isAuthenticated",
      selectedProducts: "selectedProducts/getProducts"
    }),
    ...mapState({
      bookmarkedProducts: ({ cart }) => cart.cart
    }),
    productClasses() {
      return this.product.productClasses;
    },
    productVendor() {
      return this.product.vendor;
    },
    promoClass() {
      let productClasses = [];
      let cssClass = this._.get(this.product, "warehousePromo.view.cssClass");
      if (cssClass) {
        productClasses.push("has-promo");
        productClasses.push(cssClass);
      }
      if (this.product.isNew) {
        productClasses.push("new-product");
      }
      return productClasses;
    },
    quantity: {
      get() {
        if (this.item) {
          let item = this.item;
          if (global.vm.lodash.isNil(item)) return 0;
          if (item.product.productInfos.TIPOLOGIA == "Sfuso") {
            //Handling floating point decimals issues
            var val = (item.weight * 100).toFixed(0);
            val = val * CatalogService.getMultiplier(item.product);
            val = val / 100;
            return val;
          } else {
            return item.quantity;
          }
        } else {
          if (this.internalQuantity == -1) {
            this.internalQuantity = CartService.plus(this.product);
          }
          return this.internalQuantity;
        }
      },
      set(val) {
        this.internalQuantity = val;
      }
    },
    quantityPerUnit() {
      let quantity = this.quantity;
      if (quantity === 0) return;
      if (
        this.product.productInfos.TIPOLOGIA === "Sfuso" &&
        this.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT
      ) {
        try {
          var singleUnit = Math.floor(
            quantity /
              parseFloat(this.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT)
          );
          var singleUnitUm = this.product.productInfos
            .WEIGHT_SELLING_SINGLE_UNIT_UM
            ? this.product.productInfos.WEIGHT_SELLING_SINGLE_UNIT_UM
            : "pz";
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      if (this.product.productInfos.TIPOLOGIA === "Confezione") {
        try {
          let singleUnit =
            quantity * parseFloat(this.product.productInfos.WEIGHT_SELLING);
          singleUnit = (singleUnit * 1000).toFixed(0);
          singleUnit = singleUnit / 1000;
          let singleUnitUm = this.product.productInfos.WEIGHT_UNIT_SELLING;
          return "(" + singleUnit + " " + singleUnitUm + ")";
        } catch (e) {
          console.log(e);
          return;
        }
      }
      return null;
    },
    unit() {
      if (this.product.productInfos) {
        if (this.product.productInfos.TIPOLOGIA == "Sfuso") {
          return this.product.productInfos.WEIGHT_UNIT_SELLING;
        } else {
          return this.product.productInfos.UNIT_SELLING != null
            ? this.product.productInfos.UNIT_SELLING
            : "pz";
        }
      }
    },
    highlight() {
      if (this.heartKey) return this.product.highlight || false;
      return this.product.highlight;
    }
  },
  methods: {
    ...mapActions({
      addProduct: "selectedProducts/addProduct",
      addProductToCart: "cart/addProduct",
      removeProduct: "selectedProducts/removeProduct",
      removeProductFromCart: "cart/removeItem",
      setItem: "cart/setItem",
      removeItem: "cart/removeItem",
      emptyCart: "cart/emptyCart",
      addRemoveProduct: "cart/addRemoveProduct"
    }),
    getMaxAcq(product) {
      //calcolo se esiste un limite si acquisto per il prodotto
      //prendo la prop maxAcq e se non c'è la available
      let maxAcq = this._.get(product, "maxAcq", product.available);

      //se il prodotto è un multipack, la limitazione va divisa per multipack
      let multiPack = this._.get(product, "productInfos.MULTI_PACK", 1);
      maxAcq = maxAcq / multiPack;
      return maxAcq;
    },
    async plus(updateCartParam) {
      let updateCart =
        typeof updateCartParam == "boolean"
          ? updateCartParam
          : global.config.updateCart;

      if (await this.needLogin("addtocart")) {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            let newQuantity = CartService.plus(this.product, this.quantity);
            let maxAcq = this.getMaxAcq(this.product);
            if (newQuantity <= this._.toNumber(maxAcq)) {
              if (updateCart) {
                if (this.quantity == 0) {
                  this.addProduct({
                    product: this.product,
                    quantity: newQuantity
                  });
                } else {
                  this.setItem({
                    item: this.item,
                    quantity: newQuantity,
                    isMinus: false
                  });
                }
              } else {
                this.quantity = newQuantity;
              }
            } else {
              global.EventBus.$emit("error", {
                message: "errors.maxAcq"
              });
            }
          }
        }
      }
    },
    minus(updateCartParam) {
      let updateCart =
        typeof updateCartParam == "boolean"
          ? updateCartParam
          : global.config.updateCart;

      let newQuantity = 0;
      newQuantity = CartService.minus(this.product, this.quantity);
      let minAcq = this.product.minAcq ? this.product.minAcq : 1;
      if (newQuantity == 0) {
        //remove product
        if (updateCart) {
          this.remove();
        }
      } else if (newQuantity >= minAcq) {
        if (updateCart) {
          //set item quanntity test
          this.setItem({
            item: this.item,
            quantity: newQuantity,
            isMinus: true
          });
        } else {
          this.quantity = newQuantity;
        }
      } else {
        global.EventBus.$emit("error", {
          message: "errors.minAcq"
        });
      }
    },
    async addToCart() {
      //if (await this.needLogin("addtocart")) {
      console.log("higligt", this.product.highlight);
      if (this.product.highlight === true) {
        this.addProduct({
          product: this.product,
          quantity: 1
        });
        // this.product.highlight = true;
        this.heartKey += 1;
      } else {
        this.removeProduct(this.product);
        // this.product.highlight = false;
        this.heartKey -= 1;
      }
      this.checkCart()
        ? (this.product.highlight = true)
        : (this.product.highlight = false);
      //}
    },
    async addToCarello(check = false) {
      if (await this.needLogin("addtocart")) {
        this.checkCart()
          ? (this.product.inCart = true)
          : (this.product.inCart = false);
        if (this.product.inCart === true) {
          if (check) {
            this.confirmDeleteMethod();
          } else {
            this.product.inCart = false;
            await this.removeProductFromCart(this.product);
          }
        } else {
          this.product.inCart = true;
          await this.addProductToCart({
            product: this.product,
            quantity: 1
          });
        }
        this.checkCart()
          ? (this.product.inCart = true)
          : (this.product.inCart = false);
      }
    },
    async downloadOriginaleImg() {
      if (await this.needLogin("downloadImg")) {
        cartService.getOriginalImages(
          this.product.productId
          // this.product.barcode,
          // this.product.code,
          // this.product.codeVariant
        );
      }
    },
    checkCart() {
      if (
        this.bookmarkedProducts.cartItems &&
        this.bookmarkedProducts.cartItems.some(
          e => e.product.productId === this.product.productId
        )
      ) {
        return true;
      } else return false;
    },
    async remove() {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.remove", {
          name: this.product.name,
          quantity: this.quantity,
          unit: this.unit
        })
      });
      if (res) this.removeItem(this.item);
    },
    async addToFavorites() {
      if (await this.needLogin("addtofav")) {
        let res = await this.$dialog.show(ListSelector, {
          waitForResult: true,
          fullscreen: this.$vuetify.breakpoint.smAndDown,
          width: 300
        });
        let newQuantity = this.quantity
          ? this.quantity
          : CartService.plus(this.product, this.quantity);
        if (res) {
          ListService.addProductToList(this.product, res.listId, newQuantity);
          this.product.highlight = true;
          this.heartKey += 1;
        }
      }
    },
    approxQtyMultProduct() {
      if (this.product.productInfos.QTY_LOCKED == "true") {
        var newApproxValue = Math.round(
          this.qntyInput / this.product.productInfos.QTY_MULT
        );
        this.qntyInput =
          Math.max(newApproxValue, 1) * this.product.productInfos.QTY_MULT;
      }
      if (
        this.product.productInfos.MAXACQ &&
        this.qntyInput > this.product.productInfos.MAXACQ
      )
        this.qntyInput = this.product.productInfos.MAXACQ;
    },
    async toggleShowInput(save) {
      if (
        this.product.productInfos.TIPOLOGIA == "Pezzo" ||
        this.product.productInfos.TIPOLOGIA == "Confezione"
      ) {
        if (this.showQntyInput) {
          // chiudo input
          this.showQntyInput = false;
          if (save) {
            this.approxQtyMultProduct();
            this.internalQuantity = this.qntyInput;
            if (this.item && this.item.quantity != this.internalQuantity) {
              this.setItem({
                item: this.item,
                quantity: this.internalQuantity,
                isMinus: this.internalQuantity < this.item.quantity
              });
            }
          }
        } else {
          // open and init manual input
          if (this.internalQuantity >= 0) {
            // product card and detail
            this.qntyInput = this.internalQuantity;
          } else {
            // checkout
            this.qntyInput = this.item.quantity;
          }
          this.showQntyInput = true;
          this.$nextTick(() => {
            this.$refs.manualInput.focus();
          });
        }
      }
    },
    downloadProductDetailDocument(type) {
      console.log("DOWNLOAD DOCUMENT - ", type);
    },
    modulus(value) {
      var lowerValue =
        Math.round(value / this.product.productInfos.QTY_MULT) *
        this.product.productInfos.QTY_MULT;
      var lowerResult = lowerValue;
      if (lowerValue > value) {
        lowerResult =
          (Math.round(value / this.product.productInfos.QTY_MULT) - 1) *
          this.product.productInfos.QTY_MULT;
      }
      var higherVResult =
        (Math.round(value / this.product.productInfos.QTY_MULT) + 1) *
        parseInt(this.product.productInfos.QTY_MULT);
      this.lowerInput =
        parseInt(value) <= parseInt(this.product.productInfos.QTY_MULT)
          ? this.product.productInfos.QTY_MULT
          : lowerResult;
      this.higherInput =
        lowerValue > value
          ? Math.round(value / this.product.productInfos.QTY_MULT) *
            parseInt(this.product.productInfos.QTY_MULT)
          : higherVResult;

      return (
        parseInt(value) % parseInt(this.product.productInfos.QTY_MULT) != 0
      );
    },
    async handleImageDownload() {
      if (this.isAuthenticated) {
        if (this.cartItemsCount > 0) {
          // let res = await global.vm.$dialog.confirm({
          //   text: global.vm.$t("message.emptyCart")
          // });
          // if (res) {
          //this.removeProduct();
          this.navigateToImagDownload();
          // }
        } else {
          this.navigateToImagDownload();
        }
      } else this.doLogin("imagesDownload");
    },
    async navigateToImagDownload() {
      var _this = this;
      console.log("image download obj : " + _this);
      await this.addRemoveProduct(_this.product, 1);
      this.$router.push({
        name: "GetImages",
        params: { slug: this.product.slug }
      });
    },
    async confirmDeleteMethod() {
      let result = await this.openDialog(ConfirmDelete, {
        waitForResult: true
      });
      if (result === "delete") {
        this.product.inCart = false;
        await this.removeProductFromCart(this.product);
      }
    }
  }
};
