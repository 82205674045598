import ListService from "~/service/listService";
export default {
  data() {
    return {
      lists: [],
      listName: "",
      createDisabled: false
    };
  },
  methods: {
    reload() {
      ListService.getLists().then(data => {
        this.lists = data;
      });
    },
    async createList() {
      this.createDisabled = true;
      let data = await ListService.createList(this.listName);
      this.lists = data;
      this.createDisabled = false;
      this.listName = "";
      return this.lists[this.lists.length - 1];
    },
    async setDefaultList(listId) {
      const lists = await ListService.setDefaultList(listId);
      this.lists = lists;
    },
    async removeList({ listId, listName }) {
      let res = await this.$dialog.confirm({
        text: global.vm.$t("message.removeList", {
          name: listName
        })
      });
      if (res) {
        const lists = await ListService.deleteList(listId);
        this.lists = lists;
      }
    },
    async renameList(list) {
      const lists = await ListService.renameList(list.id, list.tempName);
      this.lists = lists;
    }
  }
};
