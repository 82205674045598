<template>
  <v-card elevation="0">
    <v-card-title class="text-h5 popup-title">
      {{ $t("cart.button.confirmRemove") }}
    </v-card-title>

    <v-card-actions>
      <v-spacer></v-spacer>

      <v-btn text @click="close()">
        {{ $t("common.no") }}
      </v-btn>

      <v-btn text @click="close('delete')">
        {{ $t("common.yes") }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  methods: {
    close(option = null) {
      this.$emit("submit", option);
    }
  }
};
</script>
<style scoped lang="scss">
.popup-title {
  color: $primary;
  word-break: break-word;
}
</style>
